header {
  position: relative;
  border-radius: 8px;
  margin: 1rem auto;
  padding: 2rem 1rem;
  max-width: 1200px;
  width: calc(100% - 2rem);
  text-align: center;
  transition: all 0.8s ease; /* Aumentando a duração da transição */
  height: 100vh; /* Faz o cabeçalho ocupar a tela inteira */
}

header.scrolled {
  padding: 1rem;
  border-radius: 0;
  margin: 0;
  width: 100%;
  max-width: 95%;
  height: 80px; /* Altura fixa para o cabeçalho quando rolado */
}

.header-content {
  text-align: center;
  padding: 2rem 0;
  height: 100%; /* Faz o conteúdo ocupar a altura total do cabeçalho */
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: padding 0.8s ease, height 0.8s ease;
}

header.scrolled .header-content {
  height: 70px; /* Altura fixa para o conteúdo do cabeçalho quando rolado */
  display: flex;
  align-items: center;
}

header h1 {
  margin: 0;
  font-size: 2.5rem;
  color: #FF5722;
  transition: font-size 0.8s ease;
}

header.scrolled h1 {
  font-size: 2rem;
}

header p {
  margin: 0.5rem 0 0;
  font-size: 1.2rem;
  color: #B0BEC5;
  transition: font-size 0.8s ease;
}

header.scrolled p {
  font-size: 1rem;
}

.menu-icon {
  position: fixed;
  top: 1rem;
  left: 1rem;
  background: none;
  border: none;
  color: #FFFFFF; /* Define a cor do ícone do menu para branco */
  font-size: 2rem;
  cursor: pointer;
  display: block;
  transition: color 0.3s;
  z-index: 1001;
}

.menu-icon.show {
  display: block;
}

.menu-icon:hover {
  color: #FF8A50;
}

.nav-menu {
  display: none;
  background-color: rgba(30, 30, 30, 0.9);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.nav-menu.open {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

.nav-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.nav-menu ul li {
  margin: 1rem 0;
}

.nav-menu ul li a {
  color: #FF5722;
  text-decoration: none;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.nav-menu ul li a:hover {
  color: #FF8A50;
}

.overlay {
  display: none;
}

.overlay.active {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Media Queries for responsiveness */
@media (min-width: 480px) {
  header h1 {
    font-size: 3rem;
  }

  header p {
    font-size: 1.4rem;
  }

  .menu-icon {
    font-size: 2.5rem;
  }

  .nav-menu ul li a {
    font-size: 1.8rem;
  }
}

@media (min-width: 768px) {
  header h1 {
    font-size: 3.5rem;
  }

  header p {
    font-size: 1.6rem;
  }
}

@media (min-width: 1024px) {
  header h1 {
    font-size: 4rem;
  }

  header p {
    font-size: 1.8rem;
  }

  .menu-icon {
    display: none;
  }
}

@media (min-width: 1200px) {
  header h1 {
    font-size: 4.5rem;
  }

  header p {
    font-size: 2rem;
  }

  .nav-menu ul li a {
    font-size: 1.8rem;
  }
}

@media (min-width: 1400px) {
  header h1 {
    font-size: 5rem;
  }

  header p {
    font-size: 2.2rem;
  }

  .nav-menu ul li a {
    font-size: 2rem;
  }
}

@media (min-width: 1600px) {
  header h1 {
    font-size: 5.5rem;
  }

  header p {
    font-size: 2.4rem;
  }

  .nav-menu ul li a {
    font-size: 2.2rem;
  }
}
