#about {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #1E1E1E;
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem); /* Garante que o conteúdo ocupe a largura disponível menos as margens laterais */
}

@media (min-width: 480px) {
  #about {
    width: calc(100% - 4rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 768px) {
  #about {
    width: calc(100% - 6rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1024px) {
  #about {
    width: calc(100% - 8rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1200px) {
  #about {
    width: calc(100% - 10rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1400px) {
  #about {
    width: calc(100% - 12rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1600px) {
  #about {
    width: calc(100% - 14rem); /* Ajusta a largura para telas maiores */
  }
}

#about h2 {
  color: #FF5722;
  font-size: 2rem; /* Aumenta o tamanho da fonte */
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

#about h2::before, #about h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50px;
  height: 2px;
  background-color: #FF5722;
}

#about h2::before {
  left: -60px; /* Ajusta a posição da borda esquerda */
}

#about h2::after {
  right: -60px; /* Ajusta a posição da borda direita */
}

#about p {
  color: #B0BEC5;
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0.5rem 0 1.5rem; /* Ajusta a margem inferior para adicionar espaçamento */
  margin-right: 2rem;
  margin-left: 2rem;
}

#about p strong {
  color: #FF5722;
}

#about a {
  color: #FF5722;
  text-decoration: none;
  transition: color 0.3s;
}

#about a:hover {
  color: #FF8A50;
}

.about-icons {
  margin-top: 1rem;
}

.about-icons a {
  margin: 0 0.5rem;
  font-size: 1.5rem;
  color: #FF5722;
  transition: color 0.3s;
}

.about-icons a:hover {
  color: #FF8A50;
}
