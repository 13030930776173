footer {
  text-align: center;
  padding: 1rem 0;
  background-color: #1E1E1E;
  color: #B0BEC5;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 1rem;
  transition: background-color 0.5s, border-color 0.5s, color 0.5s;
}
