#technologies {
  text-align: center;
  padding: 2rem 1rem;
  background-color: #1E1E1E;
  border-radius: 8px;
  margin: 1rem auto;
  max-width: 1200px;
  width: calc(100% - 2rem); /* Garante que o conteúdo ocupe a largura disponível menos as margens laterais */
}

@media (min-width: 480px) {
  #technologies {
    width: calc(100% - 4rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 768px) {
  #technologies {
    width: calc(100% - 6rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1024px) {
  #technologies {
    width: calc(100% - 8rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1200px) {
  #technologies {
    width: calc(100% - 10rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1400px) {
  #technologies {
    width: calc(100% - 12rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1600px) {
  #technologies {
    width: calc(100% - 14rem); /* Ajusta a largura para telas maiores */
  }
}

#technologies h2 {
  color: #FF5722;
  font-size: 2rem; /* Aumenta o tamanho da fonte */
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

#technologies h2::before, #technologies h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50px;
  height: 2px;
  background-color: #FF5722;
}

#technologies h2::before {
  left: -60px; /* Ajusta a posição da borda esquerda */
}

#technologies h2::after {
  right: -60px; /* Ajusta a posição da borda direita */
}

.technologies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.technology {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2E2E2E;
  padding: 1rem;
  border-radius: 8px;
  text-decoration: none;
  color: #B0BEC5;
  width: 120px;
  transition: background-color 0.3s, transform 0.3s;
}

.technology:hover {
  background-color: #3E3E3E;
  transform: scale(1.05);
}

.technology .icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #FF5722;
}

.technology p {
  margin: 0;
  font-size: 1rem;
}
