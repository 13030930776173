section#projects {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 1rem auto;
  color: #CFD8DC;
  text-align: center;
  width: calc(100% - 2rem); /* Garante que o conteúdo ocupe a largura disponível menos as margens laterais */
}

@media (min-width: 480px) {
  section#projects {
    width: calc(100% - 4rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 768px) {
  section#projects {
    width: calc(100% - 6rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1024px) {
  section#projects {
    width: calc(100% - 8rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1200px) {
  section#projects {
    width: calc(100% - 10rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1400px) {
  section#projects {
    width: calc(100% - 12rem); /* Ajusta a largura para telas maiores */
  }
}

@media (min-width: 1600px) {
  section#projects {
    width: calc(100% - 14rem); /* Ajusta a largura para telas maiores */
  }
}

section#projects h2 {
  color: #FF5722;
  font-size: 2rem; /* Aumenta o tamanho da fonte */
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

section#projects h2::before, section#projects h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50px;
  height: 2px;
  background-color: #FF5722;
}

section#projects h2::before {
  left: -60px; /* Ajusta a posição da borda esquerda */
}

section#projects h2::after {
  right: -60px; /* Ajusta a posição da borda direita */
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.project-card {
  background-color: #1E1E1E;
  border: 1px solid #333;
  border-radius: 8px;
  overflow: hidden;
  width: 100%; /* Ajuste para 100% de largura */
  max-width: 300px; /* Define um valor máximo para largura */
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-color: #FF5722;
  background-color: #2E2E2E;
}

.project-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.project-card h3 {
  color: #FF5722;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.project-card p {
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 1.6;
  flex-grow: 1;
}

.project-links {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.project-links a {
  color: #FF5722;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  background-color: #2E2E2E;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s, color 0.3s;
}

.project-links a:hover {
  background-color: #FF8A50;
  transform: translateY(-5px);
  color: #1E1E1E; /* Altera a cor do texto ao passar o mouse */
}

.project-links a svg {
  margin-right: 0.5rem;
}

.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #FF5722;
  height: 300px;
  border: 1px dashed #FF5722;
  width: 100%; /* Ajuste para 100% de largura */
  max-width: 300px; /* Define um valor máximo para largura */
}
