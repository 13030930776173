#contact {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 1rem auto;
  background-color: #1E1E1E;
  border-radius: 8px;
  width: calc(100% - 2rem);
  color: #CFD8DC;
  text-align: center;
}

@media (min-width: 480px) {
  #contact {
    width: calc(100% - 4rem);
  }
}

@media (min-width: 768px) {
  #contact {
    width: calc(100% - 6rem);
  }
}

@media (min-width: 1024px) {
  #contact {
    width: calc(100% - 8rem);
  }
}

@media (min-width: 1200px) {
  #contact {
    width: calc(100% - 10rem);
  }
}

@media (min-width: 1400px) {
  #contact {
    width: calc(100% - 12rem);
  }
}

@media (min-width: 1600px) {
  #contact {
    width: calc(100% - 14rem);
  }
}

#contact h2 {
  color: #FF5722;
  font-size: 2rem; /* Aumenta o tamanho da fonte */
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

#contact h2::before, #contact h2::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50px;
  height: 2px;
  background-color: #FF5722;
}

#contact h2::before {
  left: -60px; /* Ajusta a posição da borda esquerda */
}

#contact h2::after {
  right: -60px; /* Ajusta a posição da borda direita */
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  width: 100%;
}

.contact-form label {
  color: #FF5722;
  font-size: 1rem;
  text-align: left;
}

.contact-form input, .contact-form textarea {
  padding: 0.5rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #2E2E2E;
  color: #CFD8DC;
  font-size: 1rem;
}

.contact-form button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #FF5722;
  color: #1E1E1E;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #FF8A50;
}

.contact-info {
  max-width: 500px;
  width: 100%;
}

.contact-info p {
  margin: 0.5rem 0;
}

.contact-info a {
  color: #FF5722;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-info a:hover {
  color: #FF8A50;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.social-links a {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  background-color: #2E2E2E;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: #FF5722;
  transition: background-color 0.3s, transform 0.3s;
}

.social-links a:hover {
  background-color: #FF8A50;
  transform: translateY(-5px);
}

.social-links a svg {
  margin-right: 0.5rem;
  font-size: 1.8rem;
}
