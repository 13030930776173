body {
  background-color: #121212;
  color: #FFFFFF;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(transparent, transparent), repeating-linear-gradient(90deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 1px, transparent 1px, transparent 50px), repeating-linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 1px, transparent 1px, transparent 50px);
  background-size: 100vw 100vh;
  background-attachment: fixed;
  transition: background-color 0.5s, color 0.5s;
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1c1c1c 50%, transparent 50%) 0 0 / 50px 50px, 
              linear-gradient(0deg, #1c1c1c 50%, transparent 50%) 0 0 / 50px 50px, 
              linear-gradient(90deg, transparent 75%, #1c1c1c 75%) 0 0 / 50px 50px, 
              linear-gradient(0deg, transparent 75%, #1c1c1c 75%) 0 0 / 50px 50px;
  opacity: 0.1;
  z-index: -1;
}

a {
  color: #FF5722;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  text-decoration: underline;
  color: #FF8A50;
}

.container {
  margin: 0 auto;
  padding: 0 2rem;
}

@media (min-width: 480px) {
  .container {
    padding: 0 4rem;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 0 6rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 0 8rem;
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 0 10rem;
  }
}

@media (min-width: 1400px) {
  .container {
    padding: 0 12rem;
  }
}

@media (min-width: 1600px) {
  .container {
    padding: 0 14rem;
  }
}
